.screen.internal .left-panel.list {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    padding-bottom: 100px;
}

.screen.internal .left-panel.list .title {
    font-family: 'Inter';
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.36px;
    text-align: left;
    color: #06285e;
}

.screen.internal .left-panel.list .desc {
    font-family: 'Inter';
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.22px;
    text-align: left;
    color: #0052ca;
    margin-top: 32px;
}

.screen.internal .left-panel.list .tabs-container {
    margin-top: 44px;
    flex: 1;
    display: flex;
}

.screen.internal .left-panel.list .tabs-spacer {
    display: none;
}

.screen.internal .left-panel.list .tabs {
   flex: 1 
}

.screen.internal .left-panel.list .tabs .tabs-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.screen.internal .left-panel.list .tabs .tabs-header .tab {
    border: 2px solid #c7c8ca;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 26px 38px 16px 38px;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.24px;
    text-align: center;
    color: #80c0ff;
    cursor: pointer;
}


.screen.internal .left-panel.list .tabs .tabs-header .tab.active {
    color: #0052ca;
    border-bottom: none;

}

.screen.internal .left-panel.list .tabs .tabs-header .tab-spacer {
    border-bottom: 2px solid #c7c8ca;
    flex: 1;
    margin-right: 20px;
}

.screen.internal .left-panel.list .tabs .tabs-content-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
}

.screen.internal .left-panel.list .tabs .tabs-content {
    border: 2px solid #c7c8ca;
    border-radius: 15px;
    opacity: 1;
    background-color: #fff;
    margin-top: 0px;
    border-top: none;
    border-right: none;
    border-top-left-radius: unset;
    border-bottom-right-radius: unset;
    border-top-right-radius: unset;
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
}

.screen.internal .left-panel.list .tabs .tabs-content.first {
    border-top-left-radius: unset;
}

.screen.internal .left-panel.list .tabs .tabs-content .tab-content {
    display: none;
}

.screen.internal .left-panel.list .tabs .tabs-content .tab-content.active {
    display: flex;
    flex: 1;
    padding: 10px 38px 38px 38px;
}

.screen.internal .left-panel.list .tabs .tabs-content-spacer {
    display: block;
    
    width: 20px;
    height: 100%;
    border: 2px solid #c7c8ca;
    border-left: none;
    margin-top: -1px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.screen.internal .left-panel.list .referrals {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 40px;
}

.screen.internal .left-panel.list .referrals.small {
    display: none;
}

.screen.internal .left-panel.list .referrals.large {
    display: block;
}

.screen.internal .left-panel.list .referrals.large th {
    padding: 0;
    flex: 1;
    text-transform: uppercase;
    font-family: Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: -0.18px;
    text-align: left;
    color: #c7c8ca;
    padding-bottom: 29px;    
}

.screen.internal .left-panel.list .referrals.large .value {
    padding-top: 18px;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: -0.18px;
    text-align: left;
    color: #0052ca;
}

.screen.internal .left-panel.list .referrals.large .value.name {
    font-family: 'InterSemiBold';
}

.screen.internal .left-panel.list .referrals.large .referrer-row {
    
    padding-top: 18px;
    padding-bottom: 18px;
    border-bottom: 2px solid #c7c8ca;
    
}

.screen.internal .left-panel.list .referrals.large .referrer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.screen.internal .left-panel.list .referrals.large .type-row {
    padding-top: 18px;
    padding-bottom: 18px;
    border-bottom: 2px solid #c7c8ca;   
}

.screen.internal .left-panel.list .referrals.large .referral-type {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: -0.18px;
    text-align: left;
    color: #939598;
}

.screen.internal .left-panel.list .referrals.large .referral-type img {
    width: 34px;
    height: 34px;
}

.screen.internal .left-panel.list .referrals.large .referrer .image-green {
    border: 1px solid #80d353;
    width: 30px;
    height: 30px;
    background-color: #80d353;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.screen.internal .left-panel.list .referrals.large .referrer .image-green img{
    width: 12px;
    height: 12px;
}

.screen.internal .left-panel.list .referrals.large .referrer div.name {
    margin-left: 10px;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    letter-spacing: -0.18px;
    text-align: left;
    color: #939598;
}

.screen.internal .left-panel.list .referrals.large .referrer div span {
    font-family: 'InterSemiBold';
    font-weight: bold;

}

.screen.internal .left-panel.list .referrals.large .referral{
    display: flex;
    flex-direction: column;
}

.screen.internal .left-panel.list .referrals .buttons {
    margin-top: 59px;
}

.screen.internal .left-panel.list .submit-return {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.screen.internal .left-panel.list .submit-return .message {
    font-family: 'Inter';
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.22px;
    text-align: left;
    color: #0052ca;
    margin-bottom: 32px;
}

@media screen and (max-width: 1201px) and (max-width: 1450px) {

}


@media screen and (max-width: 1200px) {

    .screen.internal {
        padding-left: 0px;
        padding-right: 0px;

    }

    .screen.internal .right-panel {
        padding-left: 8.5vw;
        padding-right: 8.5vw;
    }

    .screen.internal .left-panel.list {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        margin-top: 3.6vh;
        flex: 1;
        overflow: scroll;
    }
    
    .screen.internal .left-panel.list .title {
        font-size: 22px;
        padding-left: 8.5vw;
        padding-right: 8.5vw;
    }
    
    .screen.internal .left-panel.list .desc {
        font-size: 18px;
        margin-top: 20px;
        padding-left: 8.5vw;
        padding-right: 8.5vw;
    }
    
    .screen.internal .left-panel.list .tabs-container {
        margin-top: 3.6vh;
        flex: unset;
    }
    
    .screen.internal .left-panel.list .tabs-spacer {
        display: block;
        width: 4.25vw;
        border-bottom: 2px solid #80c0ff;
    }
    
    .screen.internal .left-panel.list .tabs {
       flex: 1 
    }
    
    .screen.internal .left-panel.list .tabs .tabs-header {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 0;
    }
    
    .screen.internal .left-panel.list .tabs .tabs-header .tab {
        border: 2px solid #80c0ff;
        flex: 1;
        padding: 26px 0px 16px 0px;
    }
    
    
    .screen.internal .left-panel.list .tabs .tabs-header .tab.active {
        color: #0052ca;
        border-bottom: none;
    
    }
    
    .screen.internal .left-panel.list .tabs .tabs-header .tab-spacer {
        display: none;
    }
    
    .screen.internal .left-panel.list .tabs .tabs-content-container {
        display: flex;
        flex-direction: row;
        flex: unset;
        height: 100%;
        border: none;
    }
    
    .screen.internal .left-panel.list .tabs .tabs-content {
        border: none;
        
    }
    
    .screen.internal .left-panel.list .tabs .tabs-content.first {
        border-top-left-radius: unset;
    }
    
    .screen.internal .left-panel.list .tabs .tabs-content .tab-content {
        display: none;
    }
    
    .screen.internal .left-panel.list .tabs .tabs-content .tab-content.active {
        display: flex;
        flex: 1;
        padding: 10px 38px 38px 38px;
        border: none;
    }
    
    .screen.internal .left-panel.list .tabs .tabs-content-spacer {
        display: none;
    }
    
    .screen.internal .left-panel.list .referrals {
        display: flex;
        flex-direction: column;
        flex: unset;
        padding-top: 0px;
        width: 100%;
    }

    .screen.internal .left-panel.list .referrals.small {
        display: flex;
        padding-top: 20px;
    }
    
    .screen.internal .left-panel.list .referrals.large {
        display: none;
    }
    
    .screen.internal .left-panel.list .referrals .referral{
        display: flex;
        flex-direction: column;
        justify-content: unset;
        margin-top: 30px;
    }
    
    .screen.internal .left-panel.list .referrals .referral .field{
        width: 100%;
    }
    
    .screen.internal .left-panel.list .referrals .referral .field.referrer{
        width: 100%;
    }
    
    .screen.internal .left-panel.list .referrals .buttons {
        margin-top: 59px;
    }

    .screen.internal .left-panel.list .referrals .buttons .button {
        width: 100%;
        text-align: left;
    }

    .screen.internal .left-panel.list .referrals.small .header {
        padding: 0;
        padding-bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        cursor: pointer;
    }

    .screen.internal .left-panel.list .referrals.small .header.closed {
        border-bottom: 2px solid #c7c8ca;
        margin-bottom: 20px;
    }

    .screen.internal .left-panel.list .referrals.small .header .name_status { 
        display: flex;
        flex-direction: column;
        font-family: 'InterSemiBold';
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.46px;
        text-align: left;
        color: var(--blue);
    }

    .screen.internal .left-panel.list .referrals.small .header .name_status span:nth-child(2) {
        font-family: 'Inter';
    }

    .screen.internal .left-panel.list .referrals.small .header .icon {
        width: 25px;
        height: 13px;   
    }

    .screen.internal .left-panel.list .referrals.small .header .icon img {
        width: 100%;
        height: 100%;
    }

    .screen.internal .left-panel.list .referrals.small .content {
        padding: 0;
        padding-bottom: 20px;
        border-bottom: 2px solid #c7c8ca;
        margin-bottom: 20px;
    }

    .screen.internal .left-panel.list .referrals.small .content.closed {
        display: none;
    }

    .screen.internal .left-panel.list .referrals.small .content .title {
        padding: 0;
        padding-bottom: 5px;
        font-family: 'Inter';
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.46px;
        text-align: left;
        color: #939598;
        padding-right: 44px;
    }

    .screen.internal .left-panel.list .referrals.small .content .value {
        padding: 0;
        padding-bottom: 5px;
        font-family: 'Inter';
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.46px;
        text-align: left;
        color: #0052ca;
        padding-right: 44px;
    }

    .screen.internal .left-panel.list .referrals.small .content .referrer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 15px;
    }

    .screen.internal .left-panel.list .referrals.small .content .referrer div.name {
        font-family: 'Inter';
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.4;
        letter-spacing: -0.46px;
        text-align: left;
        color: #939598;
        margin-left: 10px;
    }

    .screen.internal .left-panel.list .referrals.small .content .referrer div span {
        font-family: 'InterSemiBold';
    }

    .screen.internal .left-panel.list .referrals.small .referrer .image-green {
        border: 1px solid #80d353;
        width: 30px;
        height: 30px;
        background-color: #80d353;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
    }
    
    .screen.internal .left-panel.list .referrals.small .referrer .image-green img{
        width: 12px;
        height: 12px;
    }
    
    .screen.internal .left-panel.list .referrals.small .type-row {
        padding-top: 18px;
        padding-bottom: 18px;
    }
    
    .screen.internal .left-panel.list .referrals.small .referral-type {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-family: 'Inter';
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: italic;
        line-height: 1.4;
        letter-spacing: -0.46px;
        text-align: left;
        color: #939598;
    }
    
    .screen.internal .left-panel.list .referrals.small .referral-type img {
        width: 40px;
        height: 40px;
        margin-left: -5px;
        margin-right: 5px;
    }
}