@font-face {
    font-family: 'SpaceGrotesk';
    src: local('SpaceGrotesk'), url(./fonts/SpaceGrotesk-VariableFont_wght.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'Inter';
    src: local('Inter'), url(./fonts/Inter-VariableFont_slnt_wght.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'InterSemiBold';
    src: local('Inter'), url(./fonts/Inter-SemiBold.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}


  