.screen.internal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 8.5vw;
    padding-right: 8.5vw;
    align-items: flex-start;
    overflow: scroll;
    padding-top: 100px;
}

.screen.internal .left-panel {
    flex: 1;
    margin-right: 3.4vw;
}

.screen.internal .left-panel.empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.screen.internal .left-panel.empty.loading {
    width: 100%;
    padding-top: 0;
    margin-top: 0;
}

.screen.internal .left-panel.empty .hello {
    font-family: 'Inter';
    font-size: 64px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.96px;
    text-align: left;
    color: #06285e;

}

.screen.internal .left-panel.empty .description {
    width: 50%;
    font-family: 'Inter';
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.26px;
    text-align: left;
    color: #0052ca;
    margin-top: 31px;
    margin-bottom: 23px;
}

.screen.internal .left-panel.empty .description span {
    font-family: 'InterSemiBold';
    font-weight: 600;
}

.screen.internal .left-panel.empty button {
    width: 300px;
}


.screen.internal .right-panel {
    width: 26vw;
    height: 100%;
    max-width: 500px;
}

@media screen and (min-width: 1201px) and (max-width: 1450px) {
    .screen.internal {
        padding-left: 4.5vw;
        padding-right: 4.5vw;
        padding-top: 160px;
    }
}

@media screen and (max-width: 1200px) {
    .screen.internal {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        padding-top: unset;
    }
    
    .screen.internal .left-panel {
        flex: 1;
        margin-right: unset;
    }

    .screen.internal .left-panel.empty {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0px;
        padding-bottom: 12vh;
    }

    .screen.internal .left-panel.empty.loading {
        justify-content: flex-start;
    }
    
    .screen.internal .left-panel.empty .hello {
        font-size: 54px;     
        padding-left: 10vw;
        padding-right: 10vw;   
    }
    
    .screen.internal .left-panel.empty .description {
        width: 100%;
        font-size: 20px;
        padding-left: 10vw;
        padding-right: 10vw;
    }

    .screen.internal .left-panel.empty button {
        text-align: left;
        margin-left: 10vw;
        margin-right: 10vw;
    }
    
    
    
    .screen.internal .right-panel {
        width: 100%;
        height: unset;
        max-width: unset;
    }
}