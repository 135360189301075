body {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'SpaceGrotesk';
    width: 100%;
    height: 100%;
    color: #0052CB;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

#root {
    width: 100%;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
}

.App {
    width: 100%;
    height: 100%;
    padding: 0px;
    margin: 0px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.AppContent {
    width: 100%;
    height: 100%;
    padding: 0px;
    display: flex;
    flex: 1;
}

.header {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 5.3vw;
    padding-right: 5.3vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    background-color: #fff;
    width: 100%;
    z-index: 10000;
    background-color: #fff;
}

.header .logo {
    width: 200px
}

.header .logo img {
    width: 100%;
}

.header .user-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;    
}

.header .user-info span.title {
    font-family: 'Inter';
    color: #06285e;
    font-size: 20px;
    font-weight: bold;
    margin-right:5px;
}

.header .user-info span.name {
    font-family: 'InterSemiBold';
    color: #0052CB;
    font-size: 20px;
    font-weight: bold;
}

.header .user-info .profile-image {
    cursor: pointer;
    height: 43px;
    margin-left: 21px;
    padding-top: 5px;
}

.header .user-info .profile-image img{
    height: 100%;
    width: 100%;
}

.header .user-info .profile-image i {
    height: 100%;
    width: 100%;
    font-size: 30px;
}

.header .user-info .logout-image {
    cursor: pointer;
    height: 43px;
    margin-left: 21px;
    padding-top: 5px;
}

.header .user-info .logout-image i {
    height: 100%;
    width: 100%;
    font-size: 20px;
    margin-top: 5px;
    color: #dddddd;
}

.screen {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; 
    background-color: #fff;
    width: 100%;
    flex: 1;
}

textarea:focus, input:focus, button:focus{
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

input:enabled:focus, textarea:enabled:focus, select:enabled:focus, button:enabled:focus{
    outline: none;
}


*:focus {
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #06285e;
    padding: 20px;
    color: #fff;
    text-transform: uppercase;
    font-family: 'SpaceGrotesk';
    font-size: 19px;
    cursor: pointer;
    margin-right: 20px;
    border-radius: 0;
    outline: none;
    border-color: #06285e;
}

button, button:hover {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #06285e;
    padding: 20px;
    color: #fff;
    text-transform: uppercase;
    font-family: 'SpaceGrotesk';
    font-size: 19px;
    cursor: pointer;
    margin-right: 20px;
    border-radius: 0;
    outline: none;
    border-color: #06285e;
}

.fieldErrorMessage {
    font-family: 'Inter';
      color: #ff0000;
      font-size: 20px;
      margin-top: 10px;
  }

  @media screen and (max-width: 1200px) {
    .header {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10.3vw;
        padding-right: 10.3vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: unset;
    }

    .header .logo {
        width: 100px;
    }
    
    .header .logo img {
        width: 100%;
    }
    
    .header .user-info span.title,.header .user-info span.name {
        display: none;
    }
    
    .header .user-info .profile-image {
        cursor: pointer;
        height: 7.2vw;
        margin-left: unset;
    }
    
    .header .user-info .profile-image img{
        height: 100%;
        width: 100%;
    }

    .header .user-info .profile-image i {
        height: 100%;
        width: 100%;
        font-size: 20px;
    }

    .header .user-info .logout-image {
        cursor: pointer;
        height: 7.2vw;
        margin-left: 20px;
    }

    .header .user-info .logout-image i {
        height: 100%;
        width: 100%;
        font-size: 15px;
    }

    .screen {
        padding-top: 0px;
    }
  }
