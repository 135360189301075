.screen.internal .right-panel .tabs {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    height: 73px
}

.screen.internal .right-panel .tabs .tab {
    border: 2px solid #80c0ff;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    font-family: 'InterSemiBold';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.24px;
    color: #80c0ff;
    cursor: pointer;
}

.screen.internal .right-panel .tabs .tab.active {
    border-bottom: none;
    color: #0052ca;
    cursor: default;
}


.screen.internal .right-panel .content {
    border: 2px solid #80c0ff;
    border-top: none;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom-left-radius: 28px;
    border-bottom-right-radius: 28px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 42px;
    padding-bottom: 29px;
}

.screen.internal .right-panel .content .card {
    border-radius: 24px;
    width: 100%;
    margin-bottom: 18px;
    padding: 30px 37px 30px 37px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.screen.internal .right-panel .content .card.referrals {
    background-color: #80c0ff;
}

.screen.internal .right-panel .content .card.meetings {
    background-color: #0052ca;
}

.screen.internal .right-panel .content .card.sales {
    background-color: #81d553;
}

.screen.internal .right-panel .content .card .title {
    font-family: 'SpaceGrotesk';
    font-size: 19px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-bottom: 10px;
}

.screen.internal .right-panel .content .card .desc {
    font-family: 'Inter';
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.22px;
    text-align: left;
    color: #06285e;
}

.screen.internal .right-panel .content .card.meetings .desc {
    color: #80c0ff;
}

.screen.internal .right-panel .content .card .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 14px;
    align-items: center;
}

.screen.internal .right-panel .content .card .bottom div {
    font-family: 'SpaceGrotesk';
    font-size: 47px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: -0.94px;
    text-align: left;
    color: #fff;
}

.screen.internal .right-panel .content .card.referrals .bottom img {
    height: 50px;
}

.screen.internal .right-panel .content .card.meetings .bottom img {
    height: 36px;
}

.screen.internal .right-panel .content .card.sales .bottom img {
    height: 48px;
}

@media screen and (min-width: 1201px) and (max-width: 1450px) {
    .screen.internal .right-panel .tabs .tab {
        font-size: 14px;
    }
}


@media screen and (max-width: 1200px) {
    .screen.internal .right-panel .tabs {
        display: none;
    }
    
    .screen.internal .right-panel .content {
        border: none;
        flex: 1;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 0;
        margin-top: 2.7vh;
    }
    
    .screen.internal .right-panel .content .card {
        border-radius: 14px;
        height: 9.6vh;
        margin-bottom: unset;
        padding: 15px 20px 15px 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 5px;
    }
    
    .screen.internal .right-panel .content .card .title {
        font-family: 'SpaceGrotesk';
        font-size: 14px;
        line-height: unset;
        text-transform: capitalize;
    }
    
    .screen.internal .right-panel .content .card .desc {
        display: none;
    }
    
    .screen.internal .right-panel .content .card .bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin-top: unset;
    }
    
    .screen.internal .right-panel .content .card .bottom div {
        font-size: 20px;
        line-height: unset;
    }
    
    .screen.internal .right-panel .content .card.referrals .bottom img {
        height: unset;
        width:  4vw;
    }
    
    .screen.internal .right-panel .content .card.meetings .bottom img {
        height: unset;
        width:  4vw;
    }
    
    .screen.internal .right-panel .content .card.sales .bottom img {
        height: unset;
        width:  4vw;
    }
}