@import 'prismjs/themes/prism-coy.css';
@import '@fullcalendar/core/main.css';
@import '@fullcalendar/daygrid/main.css';
@import '@fullcalendar/timegrid/main.css';

.p-dialog-content {
    overflow-y: unset;
}

.p-toast {
    z-index: 10000 !important;
}

/*
.screen .title {
    color: #0052CB;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
}

.screen .subtitle {
    color: #0052CB;
    font-size: 20px;
    margin-left: 24px;
    margin-right: 24px;
}

.form-box {
    padding: 24px;
    background-color: #fff;
    border-radius: 16px;
    width: 100%;
}

.form-box .title {
    font-family: 'Inter';
    color: #58595B;
    font-size: 20px;
    font-weight: normal;
    margin-left: 24px;
    margin-right: 24px;
    line-height: 30px;
    text-align: center;
}

.form-box .buttons {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-box .buttons .button {
    margin-left: 12px;
    margin-right: 12px;
}

.p-button {
   background-color: #0052CB; 
   border-color: #0052CB;
   font-family: 'Inter';
    color: #fff;
}

.p-button:enabled:hover {
    background-color: #0052CB; 
   border-color: #0052CB;
   font-family: 'Inter';
    color: #fff;
  }

.fieldErrorMessage {
    padding: 12px;
    font-size: 12px;
    display: flex;
    color: red;
    align-items: center;
    justify-content: center;
}

.formfield {
    font-family: 'Inter';
    color: #58595B;
    padding: 5px;
    padding-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.formfieldtitle {
    font-family: 'Inter';
    color: #58595B;
    padding: 12px;
    display: flex;
    justify-content: center;
}

.formfield input {
    padding: 12px;
    width: 80%;
    text-align: center;
    font-family: 'Inter';
    color: #58595B;
    border-color: #58595B;
}

.p-password-panel {
    padding: 12px;
    width: 80%;
    text-align: center;
}

.formfield div {
    justify-content: center;
}

.formfield-label {
    font-family: 'Inter';
    color: #58595B;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome .terms-and-conditions-message {
    font-family: 'Inter';
    color: #58595B;
    padding: 12px;
    text-align: center;
}

.welcome .terms-and-conditions-message .message {
    margin-left: 12px;
}

.welcome .terms-and-conditions-message .message a {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.welcome .existing-account-message {
    font-family: 'Inter';
    color: #58595B;
    padding: 12px;
    text-align: center;
    font-size: 14px;
}

.welcome .existing-account-message a:any-link {
    font-family: 'Inter';
    color: #58595B;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.login .existing-account-message {
    font-family: 'Inter';
    color: #58595B;
    padding: 12px;
    text-align: center;
    font-size: 14px;
}

.login .existing-account-message a:any-link {
    font-family: 'Inter';
    color: #58595B;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.column-title {
    font-family: 'Inter';
    color: #58595B;
    font-size: 16px;
    font-weight: bold;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 0px;
    border-bottom: 1px solid #58595B;
}

.column-subtitle {
    font-family: 'Inter';
    color: #58595B;
    font-size: 14px;
    font-weight: normal;
    padding-top: 12px;
    padding-left: 0px;
}

.column-value {
    font-family: 'Inter';
    color: #58595B;
    font-size: 14px;
    font-weight: normal;
    padding-top: 24px;
    padding-bottom: 12px;
    border-bottom: 1px solid #58595B;
}

.formfieldcolumn {
    font-family: 'Inter';
    padding: 0;
    padding-top: 24px;
    padding-bottom: 12px;
    vertical-align: middle;
}

.formfieldcolumn input {
    font-family: 'Inter';
    width: 95%;
    border-color: #58595B;
}


.referral-error {
    color: red;
    margin-top: 5px;
    font-size: 12px;
    width: 100%;
    text-align: center;
}

@keyframes ui-progress-spinner-color {
    100% { stroke: #0052CB !important; }
    0% { stroke: #0052CB !important; }
    40% { stroke: #0052CB !important; }
    66% { stroke: #0052CB !important; }
    80% { stroke: #0052CB !important; }
    90% { stroke: #0052CB !important; }
}

.p-progress-spinner-circle {
    stroke: #0052CB;
}

.success-message {
    color: #81D553;
}

.user-error {
    padding-top: 6px;
    font-size: 12px;
    color: red;
}

.welcome-title {
    margin-bottom: 10px;
    color: #0052CB;
    font-size: 24px;
    font-weight: bold;
}

.welcome-message {
    font-family: 'Inter';
    color: #58595B;
    font-size: 14px;
    font-weight: normal;
    width: 100%;
    margin-top: 10px;
}

.welcome-subtitle {
    font-family: 'Inter';
    color: #58595B;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    width: 100%;
    margin-top: 20px;
}

.welcome-subtitle2 {
    font-family: 'Inter';
    color: #58595B;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    width: 100%;
    margin-top: 20px;
}

.welcome-faqitem {
    font-family: 'Inter';
    color: #58595B;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    margin-top: 20px;
}

.welcome-faqitemmessage {
    font-family: 'Inter';
    color: #58595B;
    font-size: 14px;
    font-weight: normal;
}

.welcome-buttons {
    margin-top: 20px;
}

.section-title {
    color: #0052CB;
    font-size: 24px;
    font-weight: bold;
    margin-top: 12px;
    margin-bottom: 12px;
    padding-left: 0px;
}

.section-subtitle {
    font-family: 'Inter';
    color: #58595B;
    padding: 10px;
    padding-left: 0px;

    display: flex;
    align-items: center;
    justify-content: left;
}

.p-button.p-button-secondary {
    background-color: #80C1FF;
    border-color: #80C1FF;
    font-family: 'Inter';
    color: #fff;
}

.p-button.p-button-secondary:enabled:hover {
    background-color: #80C1FF; 
    border-color: #80C1FF;
    font-family: 'Inter';
    color: #fff;
}

#referrals-small {
    margin-top: 20px;
    visibility: hidden;
    display: none;
}

#referrals-large {
    visibility: visible;
    display: block;
}

#profile-small {
    visibility: hidden;
    display: none;
}

#profile-large {
    visibility: visible;
}

#payment-options-small {
    visibility: hidden;
    display: none;
}

#payment-options-large {
    visibility: visible;
}

.screen.home .buttons {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
    margin-top: 50px;
}

.screen.home .buttons .button{
    width: 30%;
    height: 100px;
    // margin-left: 50px;
    // margin-right: 50px;
}

@media screen and (max-width: 800px) {
    .App {
        padding: 10px;
        min-width: 0px;
    }

    #logo {
        width: 175px;
    }

    .AppContent {
        width: 100%;
        padding: 12 px;
    }
    
    .header {
        padding: 6px;
    }   

    .form-box {
        padding: 0px;
    }

    .form-box .title {
        margin-left: 0px;
        margin-right: 0px;
    }

    .formfield input {
        width: 100%;
    }

    #referrals-small {
        visibility: visible;
        display: block;
    }
    
    #referrals-large {
        visibility: hidden;
        display: none;
    }

    #profile-small {
        visibility: visible;
        display: block;
    }
    
    #profile-large {
        visibility: hidden;
        display: none;
    }

    #payment-options-small {
        visibility: visible;
        display: block;
    }
    
    #payment-options-large {
        visibility: hidden;
        display: none;
    }

    .screen.home .buttons {
        flex-direction: column;
        width: 80%;
        align-items: center;
    }
    
    .screen.home .buttons .button{
        height: 100px;
        margin-bottom: 50px;
        width: 100%;
    }
}
*/