.referral-modal .p-dialog {
    width: 70vw;
    height: unset;
    border-radius: 25px;
    max-height: 90vh;
}

.referral-modal .p-dialog-mask {
    z-index: 1000000 !important;
}

.referral-modal .p-dialog-header {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.referral-modal .p-dialog-content {
    height: unset;
    min-height: 550px;
    overflow-y: auto;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.referral-modal .p-dialog-title {
    font-family: 'InterSemiBold';
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.36px;
    text-align: left;
    color: #06285e;
    text-transform: uppercase;
}

.referral-modal .tabs-container {
    margin-top: 0px;
    flex: 1;
    display: flex;
    margin-bottom: 60px;
}

.referral-modal .tabs-spacer {
    display: none;
}

.referral-modal .tabs {
   flex: 1 
}

.referral-modal .tabs .tabs-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.referral-modal .tabs .tabs-header .tab {
    border: 2px solid #c7c8ca;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 26px 38px 16px 38px;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.24px;
    text-align: center;
    color: #80c0ff;
    cursor: pointer;
}


.referral-modal .tabs .tabs-header .tab.active {
    color: #0052ca;
    border-bottom: none;

}

.referral-modal .tabs .tabs-header .tab-spacer {
    border-bottom: 2px solid #c7c8ca;
    flex: 1;
    margin-right: 20px;
}

.referral-modal .tabs .tabs-content-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
}

.referral-modal .tabs .tabs-content {
    border: 2px solid #c7c8ca;
    border-radius: 15px;
    opacity: 1;
    background-color: #fff;
    margin-top: 0px;
    border-top: none;
    border-right: none;
    border-top-left-radius: unset;
    border-bottom-right-radius: unset;
    border-top-right-radius: unset;
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
}

.referral-modal .tabs .tabs-content.first {
    border-top-left-radius: unset;
}

.referral-modal .tabs .tabs-content .tab-content {
    display: none;
}

.referral-modal .tabs .tabs-content .tab-content.active {
    display: flex;
    flex: 1;
    padding: 10px 38px 38px 38px;
}

.referral-modal .tabs .tabs-content-spacer {
    display: block;
    
    width: 20px;
    height: 100%;
    border: 2px solid #c7c8ca;
    border-left: none;
    margin-top: -1px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.referral-modal .referrals {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 40px;
}

.referral-modal .referrals .referral{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.referral-modal .referrals .referral .field{
    width: 23%;
    flex: 3;
    margin-left: 5px;
}

.referral-modal .referrals .referral .field.referrer{
    width: 32%;
}

.referral-modal .referrals .referral .field.type{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 2;
}

.referral-modal .referrals .referral .field.type .type-icons {
    display: flex;
}

.referral-modal .referrals .referral .field.type .type-icon {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;
}

.referral-modal .referrals .referral .field.type .type-icon img {
    width: 40px;
    height: 40px;
}

.referral-modal .referrals .referral .field.type .type-icon span {
    font-size: 10px;
    font-style: italic;
}

.referral-modal .referrals .referral .field input {
    width: 100%;
    margin-top: 7px;
    height: 50px;
    border: none;
    border-bottom: 2px solid #c7c8ca;
    border-radius: unset;
    font-family: 'Inter';
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.22px;
    text-align: left;
    color: #0052ca;
}

.referral-modal .referrals .referral .field input::placeholder {
    font-family: 'Inter';
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.22px;
    text-align: left;
    color: #0052ca;
}

.referral-error {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.22px;
    text-align: left;
    color: #ff0000;
}

.referral-modal .referrals .buttons {
    margin-top: 59px;
}

.referral-modal .submit-return {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.referral-modal .submit-return .message {
    font-family: 'Inter';
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.22px;
    text-align: left;
    color: #0052ca;
    margin-bottom: 32px;
}


@media screen and (max-width: 1200px) {

    .referral-modal .p-dialog {
        width: 90vw;
        border-radius: 25px;
        height: unset;
    }
    
    .referral-modal .p-dialog-header {
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        border-bottom: none;
    }
    
    .referral-modal .p-dialog-content {
        height: unset;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        padding-left: 0px;
        padding-right: 0px;
    }
    
    .referral-modal .tabs-container {
        margin-top: 0;
        flex: unset;
    }
    
    .referral-modal .tabs-spacer {
        display: block;
        width: 4.25vw;
        border-bottom: 2px solid #80c0ff;
    }
    
    .referral-modal .tabs {
       flex: 1 
    }
    
    .referral-modal .tabs .tabs-header {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 0;
    }
    
    .referral-modal .tabs .tabs-header .tab {
        border: 2px solid #80c0ff;
        flex: 1;
        padding: 26px 0px 16px 0px;
    }
    
    
    .referral-modal .tabs .tabs-header .tab.active {
        color: #0052ca;
        border-bottom: none;
    
    }
    
    .referral-modal .tabs .tabs-header .tab-spacer {
        display: none;
    }
    
    .referral-modal .tabs .tabs-content-container {
        display: flex;
        flex-direction: row;
        flex: unset;
        height: unset;
        border: none;
    }
    
    .referral-modal .tabs .tabs-content {
        border: none;
        
    }
    
    .referral-modal .tabs .tabs-content.first {
        border-top-left-radius: unset;
    }
    
    .referral-modal .tabs .tabs-content .tab-content {
        display: none;
    }
    
    .referral-modal .tabs .tabs-content .tab-content.active {
        display: flex;
        flex: 1;
        padding: 0px 38px 38px 38px;
        border: none;
    }
    
    .referral-modal .tabs .tabs-content-spacer {
        display: none;
    }
    
    .referral-modal .referrals {
        display: flex;
        flex-direction: column;
        flex: unset;
        padding-top: 0px;
        padding-bottom: 0px;
        width: 100%;
        margin-bottom: 0px;
    }
    
    .referral-modal .referrals .referral{
        display: flex;
        flex-direction: column;
        justify-content: unset;
        margin-top: 30px;
    }
    
    .referral-modal .referrals .referral .field{
        width: 100%;
    }
    
    .referral-modal .referrals .referral .field.referrer{
        width: 100%;
    }

    .referral-modal .referrals .referral .field.type .type-icons {
        margin-top: 10px;
    }

    .referral-modal .referrals .buttons {
        margin-top: 20px;
    }

    .referral-modal .referrals .buttons .button {
        width: 100%;
        text-align: left;
    }
}